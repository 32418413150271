<template>
    <b-card
        no-body
        class="card-payment"
    >
        <b-card-header>
            <b-card-title>Filtro</b-card-title>
        </b-card-header>
        <b-card-body>
            <b-form>
                <b-row>
                    <b-col lg="3" md="6" sm="6">
                        <b-form-group
                            label="Código VHE"
                            label-for="code"
                            class="mb-2"
                        >
                            <b-form-input
                                id="code"
                                v-model="search.vhe_code"
                                type="text"
                                autocomplete="off"
                                @keyup="searchChildrenInstallation()"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col lg="3" md="6" sm="6">
                        <b-form-group
                            label="Cliente"
                            label-for="name"
                            class="mb-2"
                        >
                            <b-form-input
                                id="name"
                                v-model="search.master_installation.client"
                                type="text"
                                autocomplete="off"
                                @keyup="searchChildrenInstallation()"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col lg="2" md="6" sm="6">
                        <b-form-group
                            label="OR"
                            label-for="name"
                            class="mb-2"
                        >
                            <b-form-input
                                id="name"
                                v-model="search.master_installation.or_number"
                                type="text"
                                autocomplete="off"
                                @keyup="searchChildrenInstallation()"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col lg="2" md="6" sm="6">
                        <b-form-group
                            label="Número de Serie"
                            label-for="name"
                            class="mb-2"
                        >
                            <b-form-input
                                id="name"
                                v-model="search.serial_number"
                                type="text"
                                autocomplete="off"
                                @keyup="searchChildrenInstallation()"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col lg="2" md="6" sm="6">
                        <b-form-group
                            label="Activa"
                            label-for="email"
                            class="mb-2"
                        >
                            <select v-model="search.active_installation" class="custom-select"
                                    @change="searchChildrenInstallation()">
                                <option value=""/>
                                <option v-for="status in boolean"
                                        :key="status.value"
                                        :value="status.value"> {{ status.name }}
                                </option>
                            </select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card-body>
    </b-card>
</template>

<script>
import {BCard, BCardHeader, BCardTitle, BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm} from 'bootstrap-vue'
import {createHelpers} from "vuex-map-fields"
import {boolean} from "@/constants/constants"


const {mapFields} = createHelpers({
    getterType: 'childrenInstallationVhe/getField',
    mutationType: 'childrenInstallationVhe/updateField',
})

export default {
    name: "SearchView",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        clickSearch: {
            type: Function,
        }
    },
    data() {
        return {
            boolean
        }
    },
    computed: {
        ...mapFields({
            search: 'search',
        }),
    },
    methods: {
        searchChildrenInstallation: function () {
            this.clickSearch()
        },
    }
}
</script>

<style scoped>

</style>